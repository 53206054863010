import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleOrderList, downloadInvoice as downloadInvoiceAction } from '../../redux/actions/OrderActions';
import Table from 'react-bootstrap/Table';
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import DataTables from "../common/DataTables";
import moment from "moment/moment";
import { toast } from "react-toastify";
import { MessageCircle } from "react-feather";
import styles from '../../../src/assets/scss/customer-profile.module.scss'

function OrderCustomerDetails() {
    let { id } = useParams();
    const { singleorderlist } = useSelector(state => state.orders);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    var columns = [
        {
            name: <b>{Capitalize("Sl.No")}</b>,
            header: <b>{Capitalize("S.No")}</b>,
            cell: (row, index) => index + 1,
            
        },
        {
            name: <b>{Capitalize("Image")}</b>,
            header: <b>{Capitalize("Image")}</b>,
            className: "item-details-table-heading",
            cell: (row) => (
                <>
                    <span>
                        <img className="ordered-img" src={row?.image}></img>
                    </span>
                </>

            )
        },
        {
            name: <b>{Capitalize("Name")}</b>,
            header: <b>{Capitalize("Name")}</b>,
            selector: row => `${row?.name}`,
            className: "item-details-table-heading"
        },
        {
            name: <b>{Capitalize("Variant")}</b>,
            header: <b>{Capitalize("Variant")}</b>,
            selector: row => `${row?.variant?.name} ${row?.unit?.name}`,
            className: "item-details-table-heading"
        },
        {
            name: <b>{Capitalize("Category")}</b>,
            header: <b>{Capitalize("Category")}</b>,
            selector: row => row?.category?.name,
            className: "item-details-table-heading"
            
        },
        {
            name: <b>{Capitalize("Sub Category")}</b>,
            header: <b>{Capitalize("Sub Category")}</b>,
            selector: row => row?.subCategory
                ?.name,
            className: "item-details-table-heading"
        },
        {
            name: <b>{Capitalize("Quantity")}</b>,
            header: <b>{Capitalize("Quantity")}</b>,
            selector: row => row?.qty,
            className: "item-details-table-heading"
        },
        {
            name: <b>{Capitalize("Price")}</b>,
            header: <b>{Capitalize("Price")}</b>,
            selector: row => `₹ ${parseFloat(row?.price || 0).toFixed(2)}`,
            style: {
                textAlign: 'left',
            },
        },
    ];

    useEffect(() => {
        if (id) {
            dispatch(getSingleOrderList(id))
        }
    }, [id])

    if (!singleorderlist) {
        return <>loading...</>
    }

    /* Download Invoice */
    const downloadInvoice = async () => {
        setLoading(true);
        try {
            // Await the dispatch to complete
            await dispatch(downloadInvoiceAction(id));
        } catch (err) {
            // Catch and handle the error
            toast.error("Download Failed");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <Breadcrumb title="Order Details" parent="Order Management" />
            <Container fluid={true}>

                <Col xl="12">
                    <Card>
                        <Row>
                            <div>
                                <div className="download-btn mt-5 mx-5">
                                    <Button
                                        disabled={loading}
                                        type="button"
                                        color="primary"
                                        onClick={downloadInvoice}
                                    >
                                        {loading ? `Downloading.....` : `Download Invoice`}
                                    </Button>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <Col xl={"12"} className="order-2 order-xl-1">
                                <div>
                                    <CardHeader>
                                        <h5>Order Information</h5>
                                    </CardHeader>
                                    <CardBody className={styles['customer-detail-body']}>
                                        {console.log(singleorderlist)}
                                        <div className={styles['order-details']}>
                                            <div className={styles['order-details-tab']}>
                                                <div className={styles['order-details-heading']}>Order Date</div>
                                                <div> {moment(singleorderlist?.orderDate, "YYYY-MM-DD").format("DD-MM-YYYY")}</div>
                                            </div>
                                            <div className={styles['order-details-tab']}>
                                                <div className={styles['order-details-heading']} >Order Status</div>
                                                <div>{singleorderlist?.orderStatus?.toUpperCase()}</div>
                                            </div>
                                            <div className={styles['order-details-tab']}>
                                                <div className={styles['order-details-heading']} >Pincode</div>
                                                <div>{singleorderlist?.billingAddress?.pincode}</div>
                                            </div>
                                            <div className={styles['order-details-tab']}>
                                                <div className={styles['order-details-heading']} >Payment Type</div>
                                                <div>{singleorderlist?.paymentType?.toUpperCase()}</div>
                                            </div>
                                            <div className={styles['order-details-tab']}>
                                                <div className={styles['order-details-heading']} >Delivery Charge</div>
                                                <div>₹ {parseFloat(singleorderlist?.delivery_charge).toFixed(2)}</div>
                                            </div>
                                            <div className={styles['order-details-tab']}>
                                                <div className={styles['order-details-heading']} >Tax</div>
                                                <div>₹ {singleorderlist?.tax && singleorderlist?.tax?.toFixed(2)}</div>
                                            </div>
                                            <div className={styles['order-details-tab']}>
                                                <div className={styles['order-details-heading']} >Total</div>
                                                <div>₹ {singleorderlist?.total && singleorderlist?.total?.toFixed(2)}</div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={"6"}>
                                <div>
                                    <CardHeader>
                                        <h5>Billing Address</h5>
                                    </CardHeader>
                                    <CardBody className={styles['customer-detail-body']}>
                                        <Table className={styles['customer-details-table']}>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">
                                                        Name:
                                                    </th>
                                                    <td>
                                                        {singleorderlist?.billingAddress?.name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        Address:
                                                    </th>
                                                    <td>
                                                    {singleorderlist?.billingAddress?.address},&nbsp;{singleorderlist?.billingAddress?.area?.address}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        Landmark:
                                                    </th>
                                                    <td>
                                                    {singleorderlist?.billingAddress?.landmark}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        Pincode:
                                                    </th>
                                                    <td>
                                                        {singleorderlist?.billingAddress?.pincode}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        Email:
                                                    </th>
                                                    <td>
                                                        {singleorderlist?.customer?.email}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        Mobile:
                                                    </th>
                                                    <td>
                                                        {singleorderlist?.billingAddress?.mobile}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </div>
                            </Col>
                            <Col xl={"6"}>
                                <div>
                                    <CardHeader>
                                        <h5>Shipping Address</h5>
                                    </CardHeader>
                                    <CardBody className={styles['customer-detail-body']}>
                                        <Table className={styles['customer-details-table']}>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">
                                                        Name:
                                                    </th>
                                                    <td>
                                                        {singleorderlist?.shippingAddress?.name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        Address:
                                                    </th>
                                                    <td>
                                                        {singleorderlist?.shippingAddress?.address},&nbsp;{singleorderlist?.shippingAddress?.area?.address}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        Landmark:
                                                    </th>
                                                    <td>
                                                        {singleorderlist?.shippingAddress?.landmark}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        Pincode:
                                                    </th>
                                                    <td>
                                                        {singleorderlist?.shippingAddress?.pincode}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        Email:
                                                    </th>
                                                    <td>
                                                        {singleorderlist?.customer?.email}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        Mobile:
                                                    </th>
                                                    <td>
                                                        {singleorderlist?.shippingAddress?.mobile}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Item Details</h5>
                            </CardHeader>
                            <CardBody className={styles['item-details-card-body']}>
                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical item-details-table">
                                    <Fragment>
                                        <DataTables
                                            columns={columns}
                                            row={singleorderlist?.itemDetails
                                                ? singleorderlist?.itemDetails : []}
                                        />
                                    </Fragment>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
export default OrderCustomerDetails
